import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createTimePickerViewModel,
  memoizedTimePickerViewModel,
} from '../timePickerViewModel/timePickerViewModel';
import {
  createDatePickerViewModel,
  memoizedDatePickerViewModel,
} from '../datePickerViewModel/datePickerViewModel';
import {
  createTimezoneSelectionViewModel,
  memoizedTimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import { DailyTimeSlotsMonthlyPickerLayoutViewModel } from '../bodyViewModel/bodyViewModel.types';

export const memoizedDailyTimeSlotsMonthlyPickerLayoutViewModel: MemoizedViewModalFactory<DailyTimeSlotsMonthlyPickerLayoutViewModel> =
  {
    dependencies: {
      settings: ['dateAndTimeSectionHeader'],
      subDependencies: [
        memoizedTimePickerViewModel.dependencies,
        memoizedDatePickerViewModel.dependencies,
        memoizedTimezoneSelectionViewModel.dependencies,
      ],
    },
    createViewModel: createDailyTimeSlotsMonthlyPickerLayoutViewModel,
  };

export function createDailyTimeSlotsMonthlyPickerLayoutViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): DailyTimeSlotsMonthlyPickerLayoutViewModel {
  const { getContent, settingsParams, experiments, isMobile } = context;

  const timezoneSelectionViewModel = !isMobile
    ? createTimezoneSelectionViewModel({
        state,
        context,
      })
    : undefined;

  return {
    bodyTitle: getContent({
      settingsParam: settingsParams.dateAndTimeSectionHeader,
      translationKey: 'app.settings.defaults.widget.date-and-time-header',
    }),
    timezoneSelectionViewModel,
    datePickerViewModel: createDatePickerViewModel({ state, context }),
    timePickerViewModel: createTimePickerViewModel({ state, context }),
  };
}
